import backofficeService from '../../api/backoffice'

export default {
  getSaleByCategory(params) {
    // console.log('params', params)
    return backofficeService.Api().get(`sales/category`, {
        params
      })
      .then(response => {
        return response.data
      })
      .catch((error) => {
        throw error
      })
  },
  getInventory(params) {
    return backofficeService.Api().get(`report/inventory/stock`, {
        params
      })
      .then(response => {
        return response.data
      })
      .catch((error) => {
        throw error
      })
  },
  getInventoryValuation(params) {
    return backofficeService.Api().get(`report/inventory/valuation`, {
        params
      })
      .then(response => {
        return response.data
      })
      .catch((error) => {
        throw error
      })
  },
  getAvailableSerial(params) {
    return backofficeService.Api().get(`report/inventory/availableSerial`, {
        params
      })
      .then(response => {
        return response.data
      })
      .catch((error) => {
        throw error
      })
  },
  getProduct(params) {
    return backofficeService.Api().get(`sales/product`, {
        params
      })
      .then(response => {
        return response.data
      })
      .catch((error) => {
        throw error
      })
  },
}