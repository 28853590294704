<template>
  <div class="invoice-view">
    <v-card flat v-if="isLoading">
      <v-card-text>
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </v-card-text>
    </v-card>
    <div v-else>
      <v-card flat>
        <ReportViewBar v-if="Inventory" :title="`Inventory Stock`" />
        <div class="text-right">
          Date Till: {{ $route.query.date | moment("LL") }}
        </div>
        <v-container fluid>
          <table class="center">
            <!-- <v-divider class="mx-3"></v-divider> -->
            <tbody class="report-container">
              <v-card-text class="pt-0">
                <v-data-table
                  dense
                  sort-by="name"
                  :headers="headers"
                  :itemsPerPage="Inventory.length"
                  hide-default-footer
                  :items="Inventory"
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <td class="text-left">{{ item.name }}</td>
                      <td class="text-center">{{ item.serialCount }}</td>
                      <td class="text-right">
                        {{ item.totalCost | currency }}
                      </td>
                    </tr>
                  </template>
                  <template
                    v-slot:body.append="{ headers }"
                    v-if="Inventory.length > 0"
                  >
                    <tr>
                      <td
                        :colspan="headers.length - 2"
                        class="text-right font-weight-bold"
                      >
                        Total
                      </td>
                      <td class="text-center font-weight-bold">
                        {{ totalQty }}
                      </td>
                      <td class="text-right font-weight-bold">
                        {{ total | currency }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card-text>
            </tbody>
          </table>
        </v-container>
      </v-card>
    </div>
  </div>
</template>

<script>
import ReportViewBar from "@/components/ReportViewBar";
import reportService from "@/modules/ReportView/service.js";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      showEmailBtn: false,
      total: 0,
      isLoading: true,
      Inventory: [],
      headers: [
        {
          text: "PRODUCT NAME",
          align: "left",
          value: "name",
          sortable: true,
        },

        {
          text: "STOCK",
          align: "center",
          value: "serialCount",
          sortable: true,
        },
        {
          text: "TOTAL COST",
          align: "right",
          value: "totalCost",
          sortable: true,
        },
      ],
      totalQty: 0,
    };
  },
  computed: {
    ...mapGetters("global", ["currentBusiness"]),
  },
  created() {
    this.loadData();
    if (!this.currentBusiness) this.initUser();
  },
  components: { ReportViewBar },
  methods: {
    ...mapActions("global", ["initUser", "initBusiness"]),
    loadData() {
      let where = {};
      if (this.$route.query.date) {
        where.date = this.$route.query.date;
      }
      reportService.getInventory(where).then((response) => {
        // console.log('response response', response)
        this.$Progress.finish();
        // this.Inventory = response.data;
        document.title = `Report - Available Inventory`;
        response.data.map((row) => {
          if (row.purchase) {
            row.serialCount = parseInt(row.purchase) - parseInt(row.invoice);
            row.totalCost = row.serialCount * row.cost;
            if (row.serialCount > 0) {
              this.total += parseFloat(row.totalCost);
              this.totalQty += parseInt(row.serialCount);

              this.Inventory.push(row);
            }
          }
        });
        this.isLoading = false;
      });
      return true;
    },
  },
};
</script>

<style scoped>
@media print {
  container {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
  .no-print {
    display: none;
  }
  .detailBox {
    overflow: visible;
    height: 100%;
  }
  * {
    background: 0 0 !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
}
.paidImg {
  position: absolute;
  left: 40%;
  top: 10%;
}

.signature {
  margin-top: 100px;
}
.center {
  background-color: white;
  margin: auto;
  width: 100%;
}
/* @media print {
  .detailBox * {
    overflow: visible;
    height: 100%;
    page-break-before: always;
  }
}
.detailBox {
  overflow: auto;
  height: 600px;
} */
</style>
